<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/agent.png" alt />
        <span class="center">权限管理</span>

        <img src="../../assets/right.png" alt />
        <span class="center">角色管理</span>
      </div>
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字:</span>
        <el-input
          placeholder="请输入角色名称"
          v-model="keywords"
          @input="loadData()"
          size="mini"
          style="margin-right: 10px; width: 250px"
          prefix-icon="el-icon-search"
          clearable
        ></el-input>
        <span class="seach_text">类型:</span>
        <el-select v-model="type" placeholder="类型" value-key="id" size="mini" @change="loadData()">
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          size="small"
          @click="roleAdd()"
          type="primary"
          icon="el-icon-plus"
          v-access
          data-ctrl="roleAdd"
          class="top_btn"
          style="margin-left:15px"
        >新建</el-button>
      </div>
    </div>

    <el-table :data="tableList" style="width: 100%；">
      <el-table-column label="角色名称">
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>

      <el-table-column prop="desc" label="角色描述">
        <template slot-scope="scope">{{ scope.row.desc }}</template>
      </el-table-column>
      <el-table-column label="类型">
        <template slot-scope="scope">{{ scope.row.type }}</template>
      </el-table-column>
      <el-table-column label="别名">
        <template slot-scope="scope">{{ scope.row.alias }}</template>
      </el-table-column>
      <el-table-column prop="enable" label="禁用">
        <template slot-scope="scope">
          <el-switch
            @change="editrole(scope.row, scope.row.enable)"
            v-model="scope.row.enable"
            v-access
            data-ctrl="roleEdit"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="delrole(scope.row.code)"
            v-access
            data-ctrl="roleDel"
            v-show="scope.row.type == 'custom'"
          >删除</el-button>
          <el-button
            type="text"
            size="small"
            v-access
            data-ctrl="setAuthEnter"
            @click="goAuthorization(scope.row.code, scope.row.name)"
          >授权</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog @close="close('ruleForm')" :visible.sync="dialogVisible" :show-close="false">
      <div slot="title" class="left_top">
        <div class="left_icon">
          <span></span>
          <span>新建角色</span>
        </div>
      </div>
      <el-form
        label-position="top"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="80px"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色别名" prop="alias">
          <el-input v-model="ruleForm.alias"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select style="width: 100%" v-model="ruleForm.type" placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input type="textarea" v-model="ruleForm.desc" maxlength="30" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align:center">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '/src/api/base';
//列表
export const roleList = api('')('/role.list.json');
export const delRole = api('')('/role.del.json');
export const addRole = api('')('/role.add.json');
export const editRole = api('')('/role.edite.json');
export default {
  data () {
    return {
      type: '',
      keywords: '',
      pageSize: 10,
      pageNumber: 1,
      dialogVisible: false,
      typeList: [
        {
          value: 'custom',
          label: '自定义',
        },
        {
          value: 'system',
          label: '系统',
        },
      ],
      ruleForm: {
        name: '',
        desc: '',
        type: '',
        alias: '',
      },
      total: 1000,
      rules: {
        name: [
          { required: true, message: '请输入角色姓名', trigger: 'change' },
        ],
        desc: [],
        alias: [
          { required: true, message: '请输入角色别名', trigger: 'change' },
        ],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
      },
      tableList: [],
    };
  },
  components: {},
  mounted () {
    this.loadData();
  },
  methods: {
    goAuthorization (code, name) {
      // this.$router.push({
      //   path: "/system/roleManagement/authorization",
      //   query: {
      //     code: code,
      //     name: name
      //   }
      // });
      this.$router.push({
        path: '/system/setAccess?type=resource',
        query: {
          type: 'access',
          code: code,
          name: name,
        },
      });
    },
    loadData () {
      const params = {
        keywords: this.keywords,
        type: this.type,
        pageSize: 1000,
        pageNumber: this.pageNumber,
      };
      roleList(params).then((res) => {
        this.tableList = res.list;

        this.total = res.total;
      });
    },
    close (formName) {
      this.$refs[formName].resetFields();
      this.closeDialog();
    },
    editrole (row, enable) {

      const params = {
        code: row.code,
        name: row.name,
        desc: row.desc,
        enable: enable,
      };
      editRole(params).then((res) => {
        console.log(res);
        this.loadData();
      });
    },
    putAway () {
      Object.keys(this.formInline).forEach((it) => {
        this.formInline[it] = '';
      });
      this.pageNumber = 1;
      this.loadData();
    },
    closeDialog () {
      this.dialogVisible = false;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          addRole({
            name: this.ruleForm.name,
            desc: this.ruleForm.desc,
          }).then((res) => {
            console.log(res);
            this.closeDialog();
            this.loadData();
          });
        } else {

          return false;
        }
      });
    },
    roleAdd () {
      this.dialogVisible = true;
    },
    delrole (code) {

      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const params = {
            code: code,
          };
          delRole(params).then((res) => {
            console.log(res);
            this.loadData();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
  },
};
</script>
<style lang="scss">
.summa {
  display: flex;
  align-items: center;
}
</style>
